import React from 'react';

function Header() {
    return (
        <header>
            <img
                src="/images/header.crdownload"
                alt="house"
                className="img-fluid"
                style={{ width: '100%', marginTop: '0px' }}
            />
            
        </header>
    );
}

export default Header;

